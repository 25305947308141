/**
 *@Author    : fengjinqi
 *@Time      : 2023/5/16 2:38 下午
 *@Email     : 1218525402@qq.com
 *@File      : index.js
 *@Software  : WebStorm
 */
import axios from "@/utils/request"
import { Md5 } from 'ts-md5';
const baseUrl = 'http://119.23.24.44:8081'
const getlist = (plat='KW') => {
    return axios.request({
        url: `${baseUrl}/song/getlist.do`,
        method: 'get',
        params:{
            plat
        }
    })
}
const play = (userid:string,songid:string) => {
    return axios.request({
        url: `${baseUrl}/play/play.do`,
        method: 'get',
        params:{
            userid,
            songid
        }
    })
}
const playList = (userid:string) => {
    return axios.request({
        url: `${baseUrl}/play/playList.do`,
        method: 'get',
        params:{
            userid,
        }
    })
}
const songRefund = (userid:number,songid:number) => {
    return axios.request({
        url: `${baseUrl}/play/refund.do`,
        method: 'get',
        params:{
            userid,
            songid
        }
    })
}
const login = (username:string,pwd:string) => {
    const md5:any = new Md5()
    md5.appendAsciiStr(pwd)
    const password = md5.end() 
    return axios.request({
        url: `${baseUrl}/user/login.do`,
        method: 'get',
        params:{
            username,
            pwd:password
        }
    })
}
//加密后的
const logins = (username:string,pwd:string) => {
    return axios.request({
        url: `${baseUrl}/user/login.do`,
        method: 'get',
        params:{
            username,
            pwd
        }
    })
}
const register = (account:string,pwd:string,email:string,name:string,sfzid:string) => {
    const md5:any = new Md5()
    md5.appendAsciiStr(pwd)
    const password = md5.end()
    let data={
        account,
        pwd:password,
        email,
        name,
        sfzid,
        phone:window.localStorage.getItem('mobile'),
        platform:'KW'
    } 
    return axios.request({
        url: `${baseUrl}/user/registr.do`,
        method: 'post',
        data
    })
}
const getCode = (phone: string) => {
    return axios.request({
        url: `${baseUrl}/user/getCode.do`,
        method: 'get',
        params:{
            phone
        }
    })
}
const checkCode = (phone: string, code: number) => {
    return axios.request({
        url: `${baseUrl}/user/checkCode.do`,
        method: 'get',
        params:{
            phone,
            code
        }
    })
}
const order = (userid: string, productName: string,money: string,returnUrl: string) => {
    return axios.request({
        url: `${baseUrl}/pay/order.do`,
        method: 'get',
        params:{
            userid,
            productName,
            money,
            returnUrl
        }
    })
}
const orderList = (userid: string) => {
    return axios.request({
        url: `${baseUrl}/pay/orderList.do`,
        method: 'get',
        params:{
            userid,
        }
    })
}
const refund = (requestid: string) => {
    return axios.request({
        url: `${baseUrl}/pay/refund.do`,
        method: 'get',
        params:{
            requestid,
        }
    })
}
const forgetPwd = (account: string) => {
    return axios.request({
        url: `${baseUrl}/user/forgetPwd.do`,
        method: 'get',
        params:{
            account,
        }
    })
}
const modifyPwd = (userid: string,pwd:string) => {
    const md5:any = new Md5()
    md5.appendAsciiStr(pwd)
    const password = md5.end()
    return axios.request({
        url: `${baseUrl}/user/modifyPwd.do`,
        method: 'get',
        params:{
            userid,
            pwd:password,
        }
    })
}
export default {
    getlist,
    play,
    playList,
    songRefund,
    login,
    register,
    getCode,
    checkCode,
    order,
    logins,
    orderList,
    refund,
    forgetPwd,
    modifyPwd
}
