/**
 *@Author    : fengjinqi
 *@Time      : 2022/5/25 11:06 上午
 *@Email     : 1218525402@qq.com
 *@File      : axios.js
 *@Software  : WebStorm
 */
import type{AxiosInstance, AxiosRequestConfig, AxiosResponse} from "axios";
import {Toast} from "vant";
import {ComponentInstance} from "vant/lib/utils";
import axios from "axios";

interface MyAxiosRequestConfig extends AxiosRequestConfig {
    showLoading?: boolean
}

class HttpAxios<T> {
    instance: AxiosInstance
    showLoading?: boolean
    loading?: ComponentInstance

    constructor(config?: MyAxiosRequestConfig) {

        this.instance = axios.create(config)
        this.showLoading = config?.showLoading

        this.instance.interceptors.response.use((response:AxiosResponse) => {
            this.loading?.clear()
            const { data, status } = response
            return { data,status }
        })
        this.instance.interceptors.request.use((config:MyAxiosRequestConfig) => {

            if (config.showLoading) {
                this.loading = Toast.loading({message: '请稍后...', duration: 0, forbidClick: true})
            }
            return config
        })
    }
    request<T>(config: MyAxiosRequestConfig): Promise<any> {
        return new Promise((resolve, reject) => {
            if (config.showLoading === false) {
                this.showLoading = config.showLoading
            }
            this.instance.request(config).then(res => {
                resolve(res)
            }).catch(err => {
                reject(err)
            })
        })
    }

}

export default HttpAxios
