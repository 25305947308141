/**
 *@Author    : fengjinqi
 *@Time      : 2022/5/25 11:24 上午
 *@Email     : 1218525402@qq.com
 *@File      : request.js
 *@Software  : WebStorm
 */
import HttpAxios from "@/utils/axios";

const request = new HttpAxios()
export default request
